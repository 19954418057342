.urbex__inputs select{
   border: 2px solid #E0DEDD;
   box-sizing: border-box;
   border-radius: 8px;
   padding: 9px 10px;
   outline: none;
   color: #5F5F5F;
   width: 100%;
}

.urbex__inputs.basis{
   flex-basis: 1px;
}

.urbex__form-inputs-full{
   margin-bottom: 20px;
   position: relative;
}

.urbex__input-full label{
   color: #595959;
   font-family: 'Rubik', sans-serif;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 5px;
}

.urbex__input-full input{
   border: 2px solid #E0DEDD;
   box-sizing: border-box;
   border-radius: 8px;
   padding: 9px 10px;
   outline: none;
   color: #5F5F5F;
   width: 100%;
}

.urbex__input-full span.inputdisabled{
   border: 2px solid #E0DEDD;
   box-sizing: border-box;
   border-radius: 8px;
   padding: 4px 10px;
   outline: none;
   color: #5F5F5F;
   width: 100%;
   display: block;
   cursor: not-allowed;
   background-color: rgba(224, 224, 224, 0.24);
}

.urbex__input-full a{
   font-family: 'Rubik', sans-serif;
   color: var(--azul);
   text-decoration: none;
   display: flex;
   align-items: center;
}

.urbex__input-full a img{
   width: 22px;
}

.urbex__form-group.adjunto{
   display: block;
}

.urbex__adjunto{
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 10px;
}

.urbex__adjunto p{
   color: #595959;
   font-family: 'Rubik', sans-serif;
   font-weight: 400;
}

.urbex__adjunto label{
   font-family: 'Rubik', sans-serif;
   color: var(--azul);
   cursor: pointer;
}

/* ADMIN ENVIO SEARCH USUARIO */
.urbex__content-search{
   display: none;
   position: absolute;
   top: 100%;
   left: 0;
   width: 100%;
   margin-top: 2px;
   border-radius: 8px;
   border: 2px solid #E0DEDD;
   background-color: #fff;
   z-index: 10;
   height: 100px;
   overflow: auto;
}

.urbex__content-search.activeSearch{
   display: block;
}

.urbex__content-search ul{
   list-style: none;
}

.urbex__content-search ul li {
   color: #5F5F5F;
   padding: 2px 5px;
   cursor: pointer;
   border-radius: 8px;
   font-family: 'Rubik', sans-serif;
}

.urbex__content-search ul li:hover{
   color: #fff;
   background: hsl(112deg 60% 43%);
}

.urbex__content-search ul li:active{
   background: hsl(112deg 60% 40%);
}


input[type="date" i]::-webkit-calendar-picker-indicator {
   cursor: pointer;
}

.urbex__descargar-adjunto a{
   color: #5F5F5F;
   margin-right: 10px;
}

.urbex__descargar-adjunto span{
   color: var(--azul);
   cursor: pointer;
}

/* paginacion */
.urbex__nex-prev{
   margin-top: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.urbex__nex-prev span{
   color: #b6b6b6;
   font-size: 30px;
   margin: 0px 15px;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   line-height: 0;
   width: 37px;
   height: 37px;
   background-color: #fff;
   border-radius: 50%;
   box-shadow: 0px 0px 5px 1px silver;
}

.urbex__nex-prev span:hover{
   color: var(--verde);
}

@media (max-width:450px){
   .urbex__adjunto{
      flex-direction: column;
      align-items: flex-start;
   }
   .urbex__adjunto p{
      margin-bottom: 10px;
   }

   .urbex__form-group .urbex__inputs{
      flex-direction: column;
      align-items: flex-start;
   }

   .urbex__inputs-radio{
      gap: 7px;
   }
}
