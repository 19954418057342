.urbex__contenido-datos-personales{
   padding: 2em 2em;
}

.urbex__form-profile{
   margin-top: 20px;
}

.urbex__form-profile form{
   max-width: 510px;
}

.urbex__form-group{
   margin-bottom: 20px;
   display: flex;
   align-items: center;
}

.urbex__form-group .urbex__inputs{
   flex-grow: 1;
}

.urbex__form-group .urbex__inputs:nth-child(1){
   margin-right: 25px;
}

.urbex__inputs label{
   color: #595959;
   font-family: 'Rubik', sans-serif;
   display: block;
   margin-bottom: 5px;
}

.urbex__inputs input:not([type="radio"]){
   border: 2px solid #E0DEDD;
   box-sizing: border-box;
   border-radius: 8px;
   padding: 9px 10px;
   outline: none;
   color: #5F5F5F;
   width: 100%;
}

.urbex__inputs span.inputdisabled{
   border: 2px solid #E0DEDD;
   box-sizing: border-box;
   border-radius: 8px;
   padding: 9px 10px;
   outline: none;
   color: #5F5F5F;
   width: 100%;
}

.urbex__linea-gruesa{
   background: #EBEBEB;
   height: 8px;
   margin: 40px 0 35px 0;
}

.urbex__form-group-button{
   margin-top: 35px;
}

.urbex__form-group-button button{
   background: hsl(112deg 60% 43%);
   box-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
   border-radius: 25px;
   border: none;
   color: #fff;
   padding: 13px 37px;
   font-weight: 300;
   font-size: 17px;
   margin-right: 15px;
   cursor: pointer;
   transition: background .4s linear;
}
.urbex__form-group-button button:hover{
   background: hsl(112deg 60% 35%);

}

/* ESTYLOS PARA USUARIO DEL ADMIN */

.urbex__label-titulo{
   color: #595959;
   font-family: 'Rubik', sans-serif;
   display: block;
   margin-right: 15px;
}

.urbex__radio{
   display: flex;
   align-items: center;
}

.urbex__inputs-radio{
   display: flex;
   align-items: center;
   gap: 15px;
}

.urbex__radio label{
   margin-left: 5px;
   margin-bottom: 0;
   cursor: pointer;
}

.urbex__inputs input[type="radio"]{
   height: 15px;
   width: 15px;
   cursor: pointer;
}

/* MEDIA QUERY */
@media (max-width:600px){
   .urbex__titulo{
      font-size: 8vw;
      text-align: center;
   }

   .urbex__label-titulo{
      align-self: flex-start;
      margin-bottom: 12px;
   }

   .urbex__form-group{
      flex-direction: column;
      margin-bottom: 0;
   }

   .urbex__form-group .urbex__inputs:nth-child(1){
      margin-right: 0;
   }

   .urbex__form-group .urbex__inputs{
      width: 100%;
   }

   .urbex__inputs {
      margin-bottom: 12px;
  }

  .urbex__linea-gruesa{
      margin: 20px 0 25px 0;
  }

}
