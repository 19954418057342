.urbex__sidebar{
   flex-basis: 22%;
   background-color: var(--azul);
   padding: 50px 20px;
}

.urbex__sidebar-admin{
   background-color:#323232;
   z-index: 10;
}

.urbex__contenido{
   flex: 1;
}

.urbex__navbar{
   padding: 1.4em 2em;
   background: #FFFFFF;
   box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
}

.urbex__icono-menu-responsive{
   display:none;
}

.urbex__menu{
   padding: 0;
   margin: 0;
   display: flex;
   align-items: center;
   list-style: none;
   justify-content: flex-end;
}

.urbex__menu-item:nth-child(1){
   margin-right: 20px;
   color:var(--azul);
   font-weight: 600;
}
.urbex__menu-item:nth-child(2){
   cursor: pointer;
   color:var(--azul);
   font-weight: 500;
}


/* estylos sidebar */
.urbex__sidebar-logo{
   text-align: center;
   margin-bottom: 50px;
}

.urbex__sidebar-titulo{
   margin: 20px auto 0 auto;
   max-width: 80%;
}

.urbex__sidebar-titulo p{
   color:#fff;
   font-family: Poppins;
   font-weight: bold;
   font-size: 18px;
   
}

.urbex__sidebar-titulo-admin p{
   text-align: center;
}

.urbex__sidebar-menu{
   max-width: 80%;
   margin: 20px auto 0 auto;
}


.urbex__sidebar-menu a{
   display: block;
   text-decoration: none;
   color: #fff;
   margin-bottom: 20px;
   font-family: Poppins;
}

.urbex__sidebar-menu a.active{
   color:var(--verde);
}

.urbex__sidebar-menu a i{
   margin-right: 10px;
}

/* stylos componentes del modal */
.urbex__estado-envio{
   display: flex;
   align-items: center;
}

.urbex__estado-envio >div{
   margin:0 40px;
   
}

.urbex__icono-logo{
   text-align: center;
   background-color:#AEADB3;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 50px;
   height: 50px;
   border-radius: 50%;
   margin: 0 auto;
   position: relative;

}

.urbex__estado-envio >div.active .urbex__icono-logo{
   background-color: #3DAE2B;
}

.urbex__estado-envio>div:not(:first-child) .urbex__icono-logo::before{
   content: '';
   position: absolute;
   top: 50%;
   left: -200%;
   width: 180%;
   height: 3px;
   transform: translateY(-50%);
   background-color: #AEADB3;
   z-index: 10;
}

.urbex__estado-envio>div:first-child{
   margin-left: 30px;
}

.urbex__estado-envio>div.active .urbex__icono-logo::before{
   background-color: #3DAE2B;
}

.urbex__estado-envio>div .texto{
   color: #595959;
   font-family: 'Rubik', sans-serif;
   margin-top: 10px;
}
 
.urbex__estado-envio>div.active .texto{
   color: #3DAE2B;
}

.urbex__form-group.modal{
   margin-bottom: 13px;
}

/* loader */

.urbex__content-loader{
   width: 100vw;
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
}

.urbex__cargando {
   color: #3DAE2B;
   font-size: 20px;
   margin: 100px auto;
   width: 1em;
   height: 1em;
   border-radius: 50%;
   position: relative;
   text-indent: -9999em;
   -webkit-animation: load4 1.3s infinite linear;
   animation: load4 1.3s infinite linear;
   -webkit-transform: translateZ(0);
   -ms-transform: translateZ(0);
   transform: translateZ(0);
 }
 @-webkit-keyframes load4 {
   0%,
   100% {
     box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
   }
   12.5% {
     box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
   }
   25% {
     box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
   }
   37.5% {
     box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
   }
   50% {
     box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
   }
   62.5% {
     box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
   }
   75% {
     box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
   }
   87.5% {
     box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
   }
 }
 @keyframes load4 {
   0%,
   100% {
     box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
   }
   12.5% {
     box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
   }
   25% {
     box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
   }
   37.5% {
     box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
   }
   50% {
     box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
   }
   62.5% {
     box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
   }
   75% {
     box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
   }
   87.5% {
     box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
   }
 }
 

/* MEDIAQUERY */
@media (max-width:1400px){
   .urbex__sidebar-titulo p{
      text-align:center
   }
}

@media (max-width:850px){

   .urbex__sidebar{
      position: absolute;
      height: 100%;
      z-index: 100;
      top: 82px;
      left: -100%;
      transition: all .4s;
      flex-basis: auto;
   }

   .urbex__sidebar.active{
      left: 0;
   }

   .urbex__navbar{
      display: flex;
      justify-content: space-between;
      align-items: center;
   }

   .urbex__icono-menu-responsive{
      display:block;
      color: var(--azul);
      font-size: 25px;
      line-height: 0;
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
   }
   .urbex__icono-menu-responsive:active{
      background-color: rgb(206 206 206 / 40%);
   }

   .urbex__menu-item span:nth-child(2){
      display: none;
   }

   .urbex__menu-item span{
      font-size: 22px;
   }
}
@media (max-width:520px){
   .urbex__estado-envio>div:first-child{
      margin: 0 10px;
    }
  
    .urbex__estado-envio >div{
      margin: 0 10px;
    }
  
    .urbex__estado-envio>div:not(:first-child) .urbex__icono-logo::before{
      left: -81%;
      width: 79%;
    }
  
    #portal > div > div > form > div.urbex__form-group-detalle.estado-envio > div > div:nth-child(2) > div.urbex__icono-logo::before{
      left: -90%;
      width: 83%;
    }
}

@media (max-width:400px){
   .urbex__menu li:nth-child(1){
      display: none;
   }
}