.login__contenedor{
   max-width: 100vw;
   min-height: 100vh;
   background-image: url(../../assets/fondo-principal-urbex.png);
   background-repeat: no-repeat;
   background-size: cover;
}

.login__contenedor{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap:30px
}

.login__contenido-formulario{
   background-color: #fff;
   padding: 35px 40px;
   box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.08);
   border-radius: 35px;
   width: 94%;
   max-width: 540px;
   
}

.login__titulo{
   margin-top: 0;
   margin-bottom: 10px;
   color:var(--azul);
   text-align: center;
}

.login_titulo,.login_subtitulo{
   text-align: center;
}

.texto-envio-correo-contrasena{
   text-align: center;
   margin-top: 25px;
   margin-bottom: 10px;
   font-family: var(--rubik);
   font-size: 17px;
   line-height: 24px;
   color: var(--verde);
}

.login__subtitulo{
   margin-top: 10px;
   color: #4D4C4D;
   text-align: center
}

.login__tag-formulario{
   margin-top: 30px;
}

.login__formulario-group {
   margin-bottom: 20px;
}

.login__formulario-group label{
   color:#2F4858;
   font-weight: bold;
   display: block;
   margin-bottom: 7px;
}

.login__formulario-group input{
   width: 100%;
   border-radius: 40px;
   border: 2px solid #E0DEDD;
   height: 40px;
   outline: none;
   padding-left: 20px;
   color: #808080;
   font-size: 16px;

}

.login__formulario-group--buttons{
   margin-top: 15px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.login__formulario-group--buttons button{
   background: hsl(112deg 60% 43%);
   box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
   border-radius: 25px;
   border: none;
   color: #fff;
   padding: 13px 37px;
   font-weight: 300;
   font-size: 17px;
   cursor: pointer;
   transition: background .4s linear;
   
}

.login__formulario-group--buttons button:hover{
   background: hsl(112deg 60% 35%);
}

.login__formulario-olvidastes-contrasena{
   color:#2F4858;
   text-decoration: none;
}

.login__pie-pagina p{
   color: #fff;

}
.login__pie-pagina p a{
   color:#2F4858;
   text-decoration: none;
}

@media only screen and (max-width: 490px) {
   .login__logo{
      margin-top: 20px;
   }
   .login__pie-pagina{
      margin-bottom: 20px;
   }
   .login__contenido-formulario{
      padding: 30px 20px;
   }
   .login__formulario-group--buttons {
      flex-direction: column;
      gap: 20px;
   }
   #root > div > div > div.login__pie-pagina > p,
   .login__formulario-olvidastes-contrasena{
      text-align: center;
   }
}