.urbex__contenido-envio{
   padding: 2em 2em;
}

.urbex__titulo{
   color:var(--azul);
   font-weight: 600;
   font-size: 35px;
}

.urbex__table-envio{
   margin-top: 20px;
}

/* Estilos para la tabla */
.urbex__table-envio table{
   border-collapse: collapse;
   max-width: 100%;
   width: 100%;
   background: #FFFFFF;
   box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.05);
   border-radius: 20px;
}

.urbex__table-envio thead{
   border-bottom: 1px solid #D9D9D9;
}

.urbex__table-envio thead th{
   font-family: Poppins;
   font-weight: 400;
   color:var(--azul2);
   text-align: center;
}

.urbex__table-envio table th,
.urbex__table-envio table td{
   padding: 10px 10px;

}

.urbex__table-envio table tbody tr:nth-child(2n+2){
   background: rgba(223, 229, 232, 0.4);
}

.urbex__table-envio table td{
   text-align: center;
   font-weight: 300;
   color:#5F5F5F

}

.td__documento{
   display: flex;  
   justify-content: center;
   align-items: center;
}

.td__documento >div{
   margin: 0 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

.td__documento >div span:nth-child(1){
   color:var(--azul);
   font-size: 17px;
}

.td__documento >div span:nth-child(2){
   font-size: 11px;
}

.td__detalle .search{
   cursor: pointer;
}

/* paginacion */
.urbex__paginacion{
   margin-top: 25px;
}

.urbex__paginacion ul{
   list-style: none;
   display: flex;
   justify-content: center;
   align-items: center;
}

.urbex__paginacion ul li{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 30px;
   height: 30px;
   font-family: 'Rubik', sans-serif;
   color:#5F5F5F;
   cursor: pointer;
}

.urbex__paginacion ul li:nth-child(2n+1){
   background: #FFFFFF;
   border: 1px solid #F2F5F6;
}

.urbex__paginacion ul li:nth-child(2n+2){
   background-color: #F2F5F6;
}

/* ================ ADMIN ================ */

.urbex__contenido-titulo-info{
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.urbex__info{
   display: flex;
   align-items: center;
}

.urbex__usuarios-buscador{
   margin-right: 25px;
   border: 2px solid #AEADB3;
   box-sizing: border-box;
   font-family: Rubik;
   padding: 8px 10px;
   min-width: 230px;
   outline: none;
   border-radius: 8px;
   color: #5F5F5F;
   background-image: url(../../assets/icon-lupa.svg);
   background-repeat: no-repeat;
   background-position: calc(100% - 1rem) center;
   background-size: 20px;
}

.urbex__link-agregar-usuarios{
   text-decoration: none;
   display: flex;
   align-items: center;
   gap: 7px;
   font-weight: 600;
   color: var(--azul);
   font-family: Rubik;

}

/* MODAL */
.urbex__form-group-detalle.estado-envio p{
   color: #595959;
   font-family: 'Rubik', sans-serif;
   display: block;
   margin-bottom: 5px;
}

/* Mediaquery */
@media (max-width:700px){
   
   .urbex__table-envio{
      overflow: auto;
   }

   .urbex__table-envio table{
      max-width: 700px;
   }

}

@media (max-width:600px){
   .urbex__info{
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
   }
   
   .urbex__usuarios-buscador{
      margin: 0;
   }
   
   .urbex__link-agregar-usuarios{
      margin-bottom: 10px;
   }
}
@media (max-width:487px){

   .urbex__info{
      flex-direction: row;
   }

   .urbex__contenido-titulo-info{
      flex-direction: column;
   }

   .urbex__contenido-titulo-info .urbex__titulo{
      margin-bottom: 10px;
   }

   .urbex__link-agregar-usuarios{
      margin-bottom: 0;
      margin-left: 10px;
   }

   .urbex__link-agregar-usuarios span{
      display: none;
   }
}


