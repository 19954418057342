:root{
  --rubik:'Rubik', sans-serif;
  --azul:#004D71;
  --azul2:#004D71;
  --verde:#3DAE2B
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

.font-rubik{
  font-family: var(--rubik);
}

.contenedor{
  display: flex;
  min-height: 100vh;
}

/* Modal */
.urbex__modal-pdf,.urbex__modal-detalle{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.82);
  z-index: 10;

}

.urbex__modal-pdf,.urbex__modal-detalle{
  display: grid;
  place-content: center;
}

.urbex__iframe-pdf{
  border-radius: 10px;
  border-style: solid;
  border-color:#AAAAAA;
  border-top-width: 20px;
  border-bottom-width: 20px;
  border-left-width: 15px;
  border-right-width: 15px;
  position: relative;
}

.urbex__iframe-pdf iframe{
  width: 800px;
  height: 500px;
}

.urbex__close-modal{
  border: none;
  background-color: #AAA;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  line-height: 0px;
  border-radius: 50%;
  color: #fff;
  font-size: 35px;
  position: absolute;
  cursor: pointer;
  
  top: 0;
  right: 0;
  top: -35px;
  right: -35px;
}

/* modal detalle */
.urbex__modal-detalle-contenido{
  position: relative;
  background: #F5F5F5;
  padding: 40px;
  border-radius: 10px;
}

.urbex__form-group-detalle{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.urbex__barra-separacion{
  background: #C4C4C4;
  height: 8px;
  margin-top: 25px;
  margin-bottom: 40px;
}

.urbex__form-group-detalle .urbex__inputs{
  margin-right: 30px;
}

.urbex__form-group-detalle .urbex__inputs.direccion{
  width: 100%;
  margin: 0;
}

.urbex__modal-detalle-contenido form .urbex__form-group-detalle:last-child {
  gap: 30px;
}

.urbex__modal-detalle-contenido form .urbex__form-group-detalle:last-child .urbex__inputs{
  margin: 0;
  flex-grow: 1;
}

.urbex__descargar-documentos{
  display: flex;
  align-items: flex-end;

}

.urbex__descargar-documentos span{
  color:#004D71;
  font-size: 25px;
}

.urbex__descargar-documentos div{
  margin-left: 20px;
}

.urbex__descargar-documentos div a{

  display: block;
  margin-bottom: 5px;
  color: #595959;
  font-family: 'Rubik', sans-serif;
  text-decoration: none;
}

.urbex__close-modal.detalle{
  top: -19px;
  right: -19px;
}

/* sobreescribiendo el bootn ok del sweealert */
.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-confirm{
  background-color: hsl(112deg 60% 43%) !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: unset !important;
}

/* mediaquery */
@media (max-width:800px){
  .urbex__iframe-pdf iframe{
    width: 85vw;
  }
}

@media (max-width:700px){

  .contenedor{
    display: block
  }

  .urbex__close-modal.detalle{
    top: 10px;
    right:10px
  }

  .urbex__modal-detalle-contenido{
    width: 98%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .urbex__form-group-detalle{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .urbex__form-group-detalle.estado-envio p{
    margin-bottom: 13px;
  }

  

}

